<template>
    <v-row>
        <v-col cols="12" class="mt-2">
            <NoticeCard v-for="(row, i) in notice_datas"
                        :key="i"
                        :row="row"
                        @childs-event="showOneNotice"
            >
            </NoticeCard>
        </v-col>

        <v-col>
                <!-- <div class="text-center"> -->
                    <v-pagination
                    v-model="currentPage"
                    :length="allPage"
                    @input="setPageData"
                    ></v-pagination>
                <!-- </div> -->
            </v-col>

        <v-dialog v-model="notice_dialog"
                  fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition"
                  style="z-index: 99"
        >
            <NoticeModal @hide="hideDialog" :item_id.sync="notice_detail_item_id">
            </NoticeModal>
        </v-dialog>

        <v-overlay :value="loading_bar">
            <v-progress-circular
                    indeterminate
                    size="64"
            ></v-progress-circular>
        </v-overlay>

    </v-row>
</template>

<script>
    import axios from '../../../service/axios'

    export default {
        name: 'Notice',
        components: {
            NoticeCard: () => import("@/components/card/NoticeCard"),
            NoticeModal: () => import("@/components/modal/NoticeModal"),
        },
        data(){
            return {
                notice_dialog: false,
                severData: [],
                notice_datas: [],
                notice_detail_item_id: null,
                page_option: {
                    pageNumber: 1,
                    itemsPerPage: 20,
                    pageCount: 0,
                },
                loading_bar: false,
                page: 1,
                currentPage: 1, 
                allPage: null, 
            }
        },

        created () {
            this.select_notice_data();
        },

        methods: {
            select_notice_data(){
                this.loading_bar = true;
                const params = {
                    options: this.page_option,
                    accessKey: '',
                }
                axios.post('/notice/list', params).then(res =>{
                    this.loading_bar = false;
                    this.severData = res.data.data.item;
                    this.page = this.severData.length
                    this.allPage = Math.ceil(this.page / 8)
                    this.notice_datas = this.severData.slice(0, 8);
                    console.log('Notice..... :', res.data.data.item)
                }).catch(error=>{
                    this.loading_bar = false;
                    console.log('페스트 오류', error)
                });
            },

            setPageData(page) {
                console.log(page)
                this.notice_datas = this.paginate(this.severData, 8, page)
            },
            paginate(array, page_size, page_number) {
                // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
                return array.slice((page_number - 1) * page_size, page_number * page_size);
            },

            showOneNotice(item_id) {
                console.log('item id ...... : ', item_id)

                const params = {
                    notice_id : item_id
                }

                axios.post('/notice/getOneNotice', params).then(res =>{
                    // this.loading_bar = false;
                    const notice_data = res.data.data.item;
                    console.log('Notice..... :', res.data.data.item)
                    this.$router.push({ name: 'noticePage', params: {data: notice_data} })
                }).catch(error=>{
                    this.loading_bar = false;
                    console.log('페스트 오류', error)
                });

                // this.notice_dialog = true;
            },

            hideDialog() {
                this.notice_detail_item_id= null;
                this.notice_dialog = false;
            },
        },
    }

</script>

<style lang="scss">
    .table_font{
        font-size: 0.8rem !important;
        letter-spacing: -0.07em;
    }
    .sale_color{
        color: #ff0000;
    }
    .buy_color{
        color: #4caf50;
    }
</style>
